<template>
  <div class="the-news-page" v-if="objNews">
    <div class="the-news-page d-flex flex-column">
      <v-container>
        <div class="the-news-page__title">
          <h1>Новости</h1>
        </div>
      </v-container>

      <div class="flex-grow-1">
        <div class="news-list">
          <v-container>
            <v-row>
              <v-col
                class="news-list__item"
                cols="12"
                sm="6"
                v-for="news in objNews"
                :key="news.id"
              >
                <a :href="'/blog/' + news.url" class="link_decoration-none">
                  <v-card class="post-teaser" flat>
                    <v-img
                      class="white--text align-end"
                      height="100%"
                      :src="'https://old.medveddoc.com' + news.image"
                    >
                      <v-card-title class="font-weight-bold title_img">
                        {{ news.name }}
                      </v-card-title>
                    </v-img>
                    <v-card-text class="text--primary">
                      <div class="post-teaser__info">
                        <h3 class="title">
                          {{ news.name }}
                        </h3>
                        <p class="description">
                          {{ news.anonce }}
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </a>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div class="pagination text-center" v-show="minPagination">
          <v-pagination
            v-model="page"
            :length="
              pagesAmount(objPagination.total_count, objPagination.page_size)
            "
            :prev-icon="icons.mdiMenuLeft"
            :next-icon="icons.mdiMenuRight"
            @input="getAllNews"
          >
          </v-pagination>
        </div>
      </div>
    </div>
    <button-back-to-top />
  </div>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import ButtonBackToTop from "components/partials/ButtonBackToTop";

export default {
  metaInfo: {
    title: "Новости",
    titleTemplate: "%s ← Medveddoc",
    meta: [
      {
        name: "description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
      { charset: "utf-8" },
      { property: "og:title", content: "Новости" },
      { property: "og:site_name", content: "medveddoc.com" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://medveddoc.com" },
      {
        property: "og:description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
    ],
  },
  name: "TheNewsPage",
  components: {
    ButtonBackToTop
  },
  created() {
    this.getAllNews(this.page);
  },
  data() {
    return {
      page: 1,
      icons: {
        mdiMenuRight,
        mdiMenuLeft,
      },
      pageAmount: "",
      minPagination: false,
    };
  },
  mounted() {},

  computed: {
    ...mapGetters({
      objNews: names.OBJ_NEWS,
      objPagination: names.OBJ_NEWS_PAGINATION,
      doctorId: names.DOCTOR_ID,
    }),
    pagesAmount() {
      return function (amount, size) {
        let total = Math.ceil(amount / size);
        if (total > 1) {
          this.minPagination = true;
          return total;
        } else {
          this.minPagination = false;
          return 1;
        }
      };
    },
  },
  methods: {
    ...mapActions({
      getNews: names.ACTION_GET_ALL_NEWS,
    }),
    getAllNews(page) {
      this.getNews({ page: page });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/helpers/helpers";
html {
  -webkit-tap-highlight-color: initial !important;
}

.the-news-page {
  max-width: 930px;
  margin: 0 auto;
  font-family: $sanspro;
  font-size: 16px;
  line-height: 17px;
  min-height: 1000px;
  @include phone-only {
    margin: 0;
  }

  &__title {
    margin-bottom: 50px;
  }

  .post-teaser {
    border: 1px solid #e6e6e6;
    height: 245px;
    overflow: hidden;
    transition: box-shadow 0.35s ease-in-out;
    position: relative;

    .title_img {
      background-color: #0044cc;
      word-wrap: break-spaces;
    }

    &__info {
      position: absolute;
      padding: 10px;
      width: 100%;
      top: 500px;
      left: 0;
      height: 100%;
      transition: top 0.5s linear;
      background-color: #0044cc;
      font-family: $montserrat;
      color: white;

      .title {
        font-weight: bold;
        @include phone-only {
          font-size: 20px !important;
        }
      }

      .description {
        @include phone-only {
          font-size: 14px !important;
        }
      }
    }

    &:hover {
      box-shadow: 0px 6px 10px rgba(0, 68, 204, 0.15);

      .post-teaser__info {
        top: 35%;
      }

      .title_img {
      }
    }
  }
}
</style>
